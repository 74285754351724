<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-button
            variant="primary"
            :to="{ name: 'question-configuration'}"
          >
            <span> {{ $t('ADD_QUESTION') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
        </b-col>
        <!-- Survey -->
        <b-col
          cols="12"
          md="4"
        >
          <v-select
            id="id-survey"
            v-model="surveyFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('SELECT_SURVEY')"
            label="name"
            :options="surveys"
          />
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="4"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('SEARCH')"
            />
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refQuestionListTable"
      :items="bindQuestions"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      class="position-relative"
    >
      <!-- Column: ID -->
      <template #cell(ID)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.questionID }}
          </span>
        </b-media>
      </template>
      <!-- Column:Statement -->
      <template #cell(Question)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            <p v-html="truncatedHtml(data.item.statement,30)" />
          </span>
        </b-media>
      </template>
      <!-- Column:Question Type -->
      <template #cell(QuestionType)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.question_type }}
          </span>
        </b-media>
      </template>
      <!-- Column:Language -->
      <template #cell(Language)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ resolveLanguage(data.item.language) }}
          </span>
        </b-media>
      </template>

      <template #cell(Categories)="data">
        <div class="d-flex flex-wrap">
          <!-- Display badges for each status in the list -->
          <b-badge
            v-for="(category, index) in data.item.categories"
            :key="index"
            :variant="category.avatar_color"
            pill
            class="text-capitalize small mr-2"
          >
            {{ category.name }}
          </b-badge>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`game-row-${data.item.questionID}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="$router.push({ name: 'question-configuration-id', params: { id: data.item.questionID }})"
          />
          <feather-icon
            :id="`game-row-${data.item.questionID}-preview-icon`"
            icon="Trash2Icon"
            size="16"
            class="cursor-pointer"
            @click="removeQuestion(data.item)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalQuestions"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow, BCol, BFormInput, BButton, BTable,
  BMedia,
  BPagination, VBPopover, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState, mapGetters } from 'vuex'
import { ref, computed } from '@vue/composition-api'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import constants from '@/constants/static.json'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import { mixinList } from '@/constants/mixinValidations'
import { paginateArray } from '@/constants/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BPagination,
    vSelect,
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    VBPopover,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [mixinDate, mixinList],
  data() {
    return {
      templateFilter: '',
      templates: [],
      surveyFilter: '',
      surveys: [],
      searchQuery: '',
      allQuestions: [],
      bindQuestions: [],
    }
  },
  setup() {
    const tableColumns = [
      { key: 'ID', sortable: false },
      { key: 'Question', sortable: false },
      { key: 'QuestionType', sortable: false },
      { key: 'Language', sortable: false },
      { key: 'Categories', sortable: false },
      { key: 'Actions' },
    ]

    const { PER_PAGE_OPTIONS, PER_PAGE } = constants
    const refQuestionListTable = ref(null)

    const perPage = ref(PER_PAGE)
    const totalQuestions = ref(0)
    const currentPage = ref(1)
    const perPageOptions = PER_PAGE_OPTIONS

    const dataMeta = computed(() => {
      const localItemsCount = refQuestionListTable.value ? refQuestionListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalQuestions.value,
      }
    })

    const refetchData = () => {
      refQuestionListTable.value.refresh()
    }

    const {
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
    } = mixinAlert()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalQuestions,
      dataMeta,
      perPageOptions,
      refQuestionListTable,
      refetchData,
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
    }
  },
  created() {
    this.fetchQuestions()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('survey', ['questions', 'questionCount']),
    ...mapGetters('survey', ['getQuestions']),
    ...mapGetters('template', ['getAllTemplates']),
    addQuestionTooltip() {
      return this.$i18n.t('TOOLTIP.QUESTION_ADD')
    },
    editQuestionTooltip() {
      return this.$i18n.t('TOOLTIP.QUESTION_EDIT')
    },
    deleteQuestionTooltip() {
      return this.$i18n.t('TOOLTIP.QUESTION_DELETE')
    },
  },
  // eslint-disable-next-line vue/order-in-components
  watch: {
    currentPage: 'fetchQuestions',
    perPage: 'fetchQuestions',
    surveyFilter: 'fetchQuestions',
    templateFilter: 'fetchQuestions',
    searchQuery: 'fetchQuestions',
  },
  methods: {
    ...mapActions('survey', ['fetchAllQuestions', 'deleteQuestion']),
    ...mapActions('template', ['fetchAllTemplates']),
    removeQuestion(data) {
      const question = this.truncatedHtml(data.statement, 200)
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} "${question}"  ${this.$i18n.t('QUESTION')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.onDeleteQuestion(data)
          }
        })
    },
    fetchQuestions() {
      if (this.allQuestions.length === 0) {
        const params = `/${this.currentPage}`
        try {
          this.fetchAllQuestions(params).then(response => {
            this.allQuestions = response.allResult
            this.totalQuestions = this.allQuestions.length
            this.bindQuestions = paginateArray(this.allQuestions, this.perPage, 1)
          })
        } catch (error) {
          this.showErrorMessage()
        }
      } else if (this.templateFilter !== '' || this.searchQuery !== '') {
        const filterData = this.filteredData()
        this.totalQuestions = filterData.length
        this.bindQuestions = paginateArray(filterData, this.perPage, this.currentPage)
      } else {
        this.totalQuestions = this.allQuestions.length
        this.bindQuestions = paginateArray(this.allQuestions, this.perPage, this.currentPage)
      }
    },
    filteredData() {
      let data = []
      const queryLowered = this.searchQuery.toLowerCase()
      if (this.templateFilter !== '' && this.searchQuery === '') {
        data = this.allQuestions.filter(question => question.template_id === this.templateFilter.ID)
      }
      if (this.templateFilter === '' && this.searchQuery !== '') {
        data = this.allQuestions.filter(
          question => (question.statement.toLowerCase().includes(queryLowered)),
        )
      }
      if (this.templateFilter !== '' && this.searchQuery !== '') {
        data = this.allQuestions.filter(
          question => (question.statement.toLowerCase().includes(queryLowered) && question.template_id === this.templateFilter.ID),
        )
      }
      return data
    },
    onDeleteQuestion(data) {
      if (data) {
        this.deleteQuestion(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.QUESTION_DELETED'))
            this.fetchQuestions()
          }
        }).catch(error => {
          if (error.response && error.response.status === 422) {
            this.warningMessage(error.response.data.message)
          } else {
            this.showErrorMessage()
          }
        })
      }
    },
    resolveLanguage(data) {
      const language = constants.LANGUAGES.find(i => i.id === data)
      if (language) {
        return language.name
      }
      return ''
    },
    navigateQuizConfiguration(data) {
      const id = data.mediaID
      const url = data.newmediapath
      this.$router.push({
        name: 'quiz-configuration-id',
        params: { id },
        query: { url },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
